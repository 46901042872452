/* tslint:disable */
/* eslint-disable */
/**
 * Mollie - Lightspeed Payments App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { MollieConnectResponse } from '../models';
/**
 * InstallationApi - axios parameter creator
 * @export
 */
export const InstallationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This URL will return a redirect to Mollie to set up OAuth permission with your Mollie acocunt. Can be run multiple times and will override previous installation attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMollie: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mollie/connect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lightspeed installation endpoint which installs the mollie app into the middleware.
         * @param {string} clusterId 
         * @param {string} language 
         * @param {string} shopId 
         * @param {string} signature 
         * @param {string} timestamp 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installGet: async (clusterId: string, language: string, shopId: string, signature: string, timestamp: string, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('installGet', 'clusterId', clusterId)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('installGet', 'language', language)
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('installGet', 'shopId', shopId)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('installGet', 'signature', signature)
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('installGet', 'timestamp', timestamp)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('installGet', 'token', token)
            const localVarPath = `/install`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clusterId !== undefined) {
                localVarQueryParameter['cluster_id'] = clusterId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['shop_id'] = shopId;
            }

            if (signature !== undefined) {
                localVarQueryParameter['signature'] = signature;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This URL will be used by Mollie to redirect to after completing the oauth flow
         * @param {string} code 
         * @param {string} state 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mollieTokenGet: async (code: string, state: string, error?: string, errorDescription?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('mollieTokenGet', 'code', code)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('mollieTokenGet', 'state', state)
            const localVarPath = `/mollie/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['error_description'] = errorDescription;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstallationApi - functional programming interface
 * @export
 */
export const InstallationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstallationApiAxiosParamCreator(configuration)
    return {
        /**
         * This URL will return a redirect to Mollie to set up OAuth permission with your Mollie acocunt. Can be run multiple times and will override previous installation attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectMollie(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MollieConnectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectMollie(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationApi.connectMollie']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lightspeed installation endpoint which installs the mollie app into the middleware.
         * @param {string} clusterId 
         * @param {string} language 
         * @param {string} shopId 
         * @param {string} signature 
         * @param {string} timestamp 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async installGet(clusterId: string, language: string, shopId: string, signature: string, timestamp: string, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.installGet(clusterId, language, shopId, signature, timestamp, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationApi.installGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This URL will be used by Mollie to redirect to after completing the oauth flow
         * @param {string} code 
         * @param {string} state 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mollieTokenGet(code: string, state: string, error?: string, errorDescription?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mollieTokenGet(code, state, error, errorDescription, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationApi.mollieTokenGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InstallationApi - factory interface
 * @export
 */
export const InstallationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstallationApiFp(configuration)
    return {
        /**
         * This URL will return a redirect to Mollie to set up OAuth permission with your Mollie acocunt. Can be run multiple times and will override previous installation attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMollie(options?: RawAxiosRequestConfig): AxiosPromise<MollieConnectResponse> {
            return localVarFp.connectMollie(options).then((request) => request(axios, basePath));
        },
        /**
         * Lightspeed installation endpoint which installs the mollie app into the middleware.
         * @param {string} clusterId 
         * @param {string} language 
         * @param {string} shopId 
         * @param {string} signature 
         * @param {string} timestamp 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installGet(clusterId: string, language: string, shopId: string, signature: string, timestamp: string, token: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.installGet(clusterId, language, shopId, signature, timestamp, token, options).then((request) => request(axios, basePath));
        },
        /**
         * This URL will be used by Mollie to redirect to after completing the oauth flow
         * @param {string} code 
         * @param {string} state 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mollieTokenGet(code: string, state: string, error?: string, errorDescription?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.mollieTokenGet(code, state, error, errorDescription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstallationApi - object-oriented interface
 * @export
 * @class InstallationApi
 * @extends {BaseAPI}
 */
export class InstallationApi extends BaseAPI {
    /**
     * This URL will return a redirect to Mollie to set up OAuth permission with your Mollie acocunt. Can be run multiple times and will override previous installation attempt.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public connectMollie(options?: RawAxiosRequestConfig) {
        return InstallationApiFp(this.configuration).connectMollie(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lightspeed installation endpoint which installs the mollie app into the middleware.
     * @param {string} clusterId 
     * @param {string} language 
     * @param {string} shopId 
     * @param {string} signature 
     * @param {string} timestamp 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public installGet(clusterId: string, language: string, shopId: string, signature: string, timestamp: string, token: string, options?: RawAxiosRequestConfig) {
        return InstallationApiFp(this.configuration).installGet(clusterId, language, shopId, signature, timestamp, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This URL will be used by Mollie to redirect to after completing the oauth flow
     * @param {string} code 
     * @param {string} state 
     * @param {string} [error] 
     * @param {string} [errorDescription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public mollieTokenGet(code: string, state: string, error?: string, errorDescription?: string, options?: RawAxiosRequestConfig) {
        return InstallationApiFp(this.configuration).mollieTokenGet(code, state, error, errorDescription, options).then((request) => request(this.axios, this.basePath));
    }
}

