import React, {createContext, PropsWithChildren, useEffect, useState} from 'react'
import {configurationService} from '../plugins/axios'
import {
    ApplicationCountry,
    ApplicationLocale,
    Merchant,
    MerchantProfileSave,
    MethodsOrderSave,
    MollieMethod,
    MollieProfile,
    PaymentMethodUpdate,
    TransactionPollStatus,
} from '../plugins/middleware-api-client'
import {useNavigate} from 'react-router-dom'
import i18n from 'i18next'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import locale from '../plugins/locale'

interface UserContextProps {
    token: string | null
    setToken: (token: string | null) => void
    merchant: Merchant | null
    setMerchant: (token: Merchant | null) => void
    merchantSettingsPost: (
        test_mode: boolean,
        locale: string,
    ) => Promise<boolean>
    profiles: MollieProfile[]
    setProfiles: (profiles: MollieProfile[]) => void
    getProfiles: () => Promise<boolean>
    loaded: boolean
    postProfile: (merchantProfileSave: MerchantProfileSave) => Promise<boolean>
    paymentMethods: MollieMethod[]
    setPaymentMethods: (paymentMethods: MollieMethod[]) => void
    getPaymentMethods: (country?: string | null) => Promise<MollieMethod[]>
    updatePaymentMethodsOrder: (
        methods: MethodsOrderSave,
        country?: string | null,
    ) => Promise<boolean>
    updatePaymentMethod: (
        paymentMethod: PaymentMethodUpdate,
        country?: string | null,
    ) => Promise<boolean>
    togglePaymentMethod: (enabled: boolean, method: string) => Promise<boolean>
    locales: ApplicationLocale[]
    getLocales: () => Promise<ApplicationLocale[] | false>
    countries: ApplicationCountry[]
    getCountries: () => Promise<boolean>
    disconnectMollie: () => Promise<boolean>
    addCountrySetting: (
        applicationCountry: ApplicationCountry,
    ) => Promise<boolean>
    removeCountrySetting: (
        applicationCountry: ApplicationCountry,
    ) => Promise<boolean>
    pollTransaction: () => Promise<TransactionPollStatus>
}

export const UserContext = createContext({} as UserContextProps)

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [merchant, setMerchant] = useState<Merchant | null>(null)
    const [profiles, setProfiles] = useState<MollieProfile[]>([])
    const [paymentMethods, setPaymentMethods] = useState<MollieMethod[]>([])
    const [locales, setLocales] = useState<ApplicationLocale[]>([])
    const [countries, setCountries] = useState<ApplicationCountry[]>([])
    const navigate = useNavigate()
    const { t } = useTranslation()
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    useEffect(() => {
        handleToken()
    }, [])

    const handleToken = async () => {
        let params = new URLSearchParams(window.location.search)
        let paramToken = params.get('token')
        if (paramToken) {
            localStorage.setItem('token', paramToken)
        }
        await setToken(localStorage.getItem('token'))
        if (!localStorage.getItem('token')) {
            setLoaded(true)
        }
    }

    useEffect(() => {
        if (!token) return
        getMerchant()
    }, [token])

    const getMerchant = async () => {
        try {
            const response = await configurationService.merchantMeGet(options)
            if (response.data.locale) {
                await i18n.changeLanguage(response.data.locale)
            }
            console.log(response.data)
            setMerchant(response.data)
            setLoaded(true)
        } catch (e: any) {
            setLoaded(true)
            if (e.response.status === 401) {
                navigate('/unauthorized')
            }
            return false
        }
        return true
    }

    const merchantSettingsPost = async (test_mode: boolean, locale: string) => {
        return await configurationService
            .merchantSettingsPost(
                {
                    test_mode: test_mode,
                    locale: locale,
                },
                options,
            )
            .then((res) => {
                if (res.status === 200) {
                    toast.success(t('account:profile.saved'))
                    getMerchant()
                    return true
                }
                toast.error(t('account:profile.save-error'))
                return false
            })
            .catch((e) => {
                toast.error(t('account:profile.save-error'))
                throw Error(e)
            })
    }

    const getProfiles = async () => {
        try {
            const response = await configurationService.merchantProfilesGet(
                options,
            )
            setProfiles(response.data.mollie_profiles)
        } catch (e) {
            console.error(e)
            return false
        }
        return true
    }

    const getPaymentMethods = async (country?: string | null) => {
        if (!country) country = undefined
        let l = locale.getLocale(i18n.language)
        const response = await configurationService.merchantMethodsGet(
            country,
            l || undefined,
            options,
        )
        let paymentMethods = response.data.methods.sort((a, b) => {
            if (a.position === null || b.position === null) return 0
            return a.position - b.position
        })
        setPaymentMethods(paymentMethods)
        return paymentMethods
    }

    const updatePaymentMethod = async (
        paymentMethod: PaymentMethodUpdate,
        country?: string | null,
    ) => {
        try {
            await configurationService.merchantMethodsPut(
                paymentMethod,
                options,
            )
            await getPaymentMethods(country)
        } catch (e) {
            console.log(e)
            return false
        }
        return true
    }

    const updatePaymentMethodsOrder = async (
        methods: MethodsOrderSave,
        country?: string | null,
    ) => {
        try {
            await configurationService.merchantMethodsOrderPost(
                methods,
                options,
            )
            await getPaymentMethods(country)
        } catch (e) {
            console.log(e)
            return false
        }
        return true
    }

    const togglePaymentMethod = async (enabled: boolean, method: string) => {
        let merchantPaymentMethod = {
            method: method,
        }
        try {
            if (enabled) {
                await configurationService.merchantMethodsEnablePost(
                    merchantPaymentMethod,
                    options,
                )
            } else {
                await configurationService.merchantMethodsDisablePost(
                    merchantPaymentMethod,
                    options,
                )
            }
        } catch (e) {
            console.log(e)
            return false
        }
        return true
    }

    const postProfile = async (merchantProfileSave: MerchantProfileSave) => {
        return await configurationService
            .merchantProfilePost(merchantProfileSave, options)
            .then(async (res) => {
                await getMerchant()
                toast.success(t('account:profile.saved'))
                return res.status === 200
            })
            .catch((e) => {
                toast.error(t('account:profile.save-error'))
                throw Error(e)
            })
    }

    const getLocales = async () => {
        try {
            const response = await configurationService.merchantLocalesGet(
                options,
            )
            setLocales(response.data.locales)
            return response.data.locales
        } catch (e) {
            console.error(e)
            return false
        }
    }

    const getCountries = async () => {
        try {
            const response = await configurationService.merchantCountriesGet(
                options,
            )
            setCountries(response.data.countries)
        } catch (e) {
            console.error(e)
            return false
        }
        return true
    }

    const addCountrySetting = async (
        applicationCountry: ApplicationCountry,
    ) => {
        return await configurationService
            .merchantCountriesPost(applicationCountry, options)
            .then((res) => {
                toast.success(
                    t('payment:settings.country-saved', {
                        country: applicationCountry.name,
                    }),
                )
                return res.status === 200
            })
            .catch((e) => {
                toast.error(
                    t('payment:settings.country-save-error', {
                        country: applicationCountry.name,
                    }),
                )
                throw Error(e)
            })
    }

    const removeCountrySetting = async (
        applicationCountry: ApplicationCountry,
    ) => {
        return await configurationService
            .merchantCountriesDelete(applicationCountry, options)
            .then((res) => {
                toast.success(
                    t('payment:settings.country-removed', {
                        country: applicationCountry.name,
                    }),
                )
                return res.status === 200
            })
            .catch((e) => {
                toast.error(
                    t('payment:settings.country-remove-error', {
                        country: applicationCountry.name,
                    }),
                )
                throw Error(e)
            })
    }

    const disconnectMollie = async () => {
        try {
            const response = await configurationService.merchantDisconnectPost(
                options,
            )
            if (response) {
                localStorage.removeItem('token')
                navigate('/disconnected')
            }
        } catch (e) {
            console.error(e)
            return false
        }
        return true
    }
    const pollTransaction = async () => {
        try {
            const response = await configurationService.pollTransaction(options)
            return response.data
        } catch (e) {
            console.error(e)
            throw Error('Something went wrong')
        }
    }

    return (
        <UserContext.Provider
            value={{
                token,
                setToken,
                merchant,
                setMerchant,
                merchantSettingsPost,
                profiles,
                setProfiles,
                getProfiles,
                loaded,
                postProfile,
                paymentMethods,
                setPaymentMethods,
                getPaymentMethods,
                updatePaymentMethodsOrder,
                updatePaymentMethod,
                togglePaymentMethod,
                locales,
                getLocales,
                countries,
                getCountries,
                disconnectMollie,
                addCountrySetting,
                removeCountrySetting,
                pollTransaction,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
