import React, {PropsWithChildren} from 'react'

interface SpanProps extends PropsWithChildren{
    classList?: string
}

const Span: React.FC<SpanProps> = ({ classList, children }) => {
    if (!classList) classList = 'text-base text-brand-gray-500'
    return <span className={classList}>{children}</span>
}

export default Span
