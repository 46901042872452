import React, {PropsWithChildren} from 'react'
import {classNames} from '../../utils/classNames'

interface IButtonProps extends PropsWithChildren {
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined
  classList?: string
  action?: Action
  inverted?: boolean
  loading?: boolean
}

export enum Action {
  BLUE,
  RED,
  GRAY,
}

const ACTION_MAPS: Record<Action, string> = {
  [Action.BLUE]:
    'bg-brand-primary hover:bg-brand-primary-600 focus:bg-brand-primary-400 text-white ring-brand-primary-400',
  [Action.RED]:
    'bg-brand-red hover:bg-brand-red-600 focus:bg-brand-red-400 text-white ring-brand-red-400',
  [Action.GRAY]: 'shadow-none bg-brand-gray-150 text-black hover:bg-brand-gray-200',
}

const ACTION_INVERTED_MAPS: Record<Action, string> = {
  [Action.BLUE]:
    'text-brand-primary hover:text-brand-primary-600 focus:underline-brand-primary-400',
  [Action.RED]: 'bg-brand-red hover:bg-brand-red-600 focus:bg-brand-red-400',
  [Action.GRAY]: 'shadow-none text-brand-gray-500 underline text-xs',
}

const Button: React.FC<IButtonProps> = ({
                                          onClick,
                                          action,
                                          inverted = false,
                                          classList = '',
                                          loading = false,
                                          children,
                                          ...rest
                                        }) => {
  if (!action) action = Action.BLUE
  let actionMaps = ACTION_MAPS[action]
  let defaultClasses =
    'focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md shadow-sm  font-bold'
  if (inverted) {
    actionMaps = ACTION_INVERTED_MAPS[action]
    defaultClasses = 'focus:outline-none focus:underline font-normal'
  }
  return (
    <button
      onClick={onClick}
      className={classNames(
        'text-center items-center px-4 py-2 border border-transparent text-sm inline-flex',
        defaultClasses,
        actionMaps,
        classList,
      )}
      {...rest}
      disabled={loading}
    >
      {loading && (
        <svg
          className="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {children}
    </button>
  )
}

export default Button
