import React, { useContext, useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Heading, {
    HeadingVariant,
    Level,
} from '../../components/Typography/Heading'
import Button, { Action } from '../../components/Button/Button'
import Select, { FormOption } from '../../components/Form/Select'
import { UserContext } from '../../providers/UserProvider'
import { LayoutContext } from '../../providers/LayoutProvider'
import { useNavigate } from 'react-router-dom'
import { MerchantProfileSave } from '../../plugins/middleware-api-client'
import { useTranslation } from 'react-i18next'

const Organisation: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userContext = useContext(UserContext)
    const layoutContext = useContext(LayoutContext)
    const [formOptions, setFormOptions] = useState<FormOption[]>([])
    const [selectedOption, setSelectedOption] = useState<FormOption | null>(
        null,
    )
    const [isSavingProfile, setIsSavingProfile] = useState<boolean>(false)

    useEffect(() => {
        userContext.getProfiles().then(() => {
            return
        })
    }, [])

    useEffect(() => {
        if (userContext.profiles && userContext.profiles[0]) {
            const options: FormOption[] = userContext.profiles.map(
                (profile) => {
                    return {
                        label: profile.name,
                        value: profile.id,
                    }
                },
            )
            setFormOptions(options)
            setSelectedOption(options[0])
        }
    }, [userContext.profiles])

    useEffect(() => {
        if (userContext.merchant?.active_mollie_profile_id) {
            navigate('/')
        }
    }, [userContext.merchant])

    const selectProfile = async () => {
        if (selectedOption) {
            setIsSavingProfile(true)
            let merchantProfileSave: MerchantProfileSave = {
                mollie_api_type: '',
                profile_id: selectedOption.value || '',
                process_credit_note_webhook: true,
                use_mollie_method_names: false,
                notify_on_cancellation: false
            }
            await userContext.postProfile(merchantProfileSave).catch(() => {
                setIsSavingProfile(false)
            })
            setIsSavingProfile(false)
        }
    }

    return (
        <>
            <div className="flex flex-col min-h-screen">
                <Header>&nbsp;</Header>
                <div className="flex flex-col min-h-screen justify-center items-center text-center">
                    <div className="max-w-md p-6">
                        <Heading
                          headingVariant={HeadingVariant.BLACK}
                          level={Level.HEADING_2}
                        >
                            {t('account:profile.select-profile', {
                                profile:
                                userContext.merchant
                                  ?.active_mollie_organization,
                            })}
                        </Heading>
                        <div className="py-6 mb-2">
                            <span>
                                {t('account:profile.mollie-api-type-text')}
                            </span>
                        </div>
                        {selectedOption && (
                          <>
                              <Select
                                id="profile"
                                options={formOptions}
                                selected={selectedOption}
                                setSelected={setSelectedOption}
                              />
                              <div className="flex justify-center pt-8 gap-x-4">
                                  <Button
                                    action={Action.RED}
                                    onClick={() =>
                                      layoutContext.setShowAlertModal(
                                        true,
                                      )
                                    }
                                  >
                                      {t('account:profile.disconnect')}
                                  </Button>
                                  <Button
                                    onClick={() => selectProfile()}
                                    loading={isSavingProfile}
                                  >
                                      {t('account:profile.select')}
                                  </Button>
                              </div>
                          </>
                        )}
                    </div>
                    <div className="max-w-xs border-t border-gray-200 mt-8">
                        <Button
                          action={Action.BLUE}
                          inverted={true}
                          onClick={() => navigate('/connect')}
                        >
                            {t('connect:switch-organization')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Organisation
