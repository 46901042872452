import React, { Fragment, useEffect, useRef, useState } from 'react'
import {Dialog, DialogPanel, Transition} from '@headlessui/react'
import Heading, { HeadingVariant, Level } from '../Typography/Heading'
import Select, { FormOption } from '../Form/Select'

interface AlertProps {
    show: boolean
    handleClose: (show: boolean) => void
    handleAction: (selectedCountry: FormOption) => void
    countries: FormOption[]
}

const AddCountry: React.FC<AlertProps> = ({
    show,
    handleAction,
    handleClose,
    countries,
}) => {
    const cancelButtonRef = useRef(null)
    const [selectedCountry, setSelectedCountry] = useState<FormOption>({
        label: '',
        value: '',
    })

    useEffect(() => {
        setSelectedCountry(countries[0])
    }, [countries])
    return (
        <>
            <Transition.Root show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    onClose={handleClose}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <DialogPanel className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded pt-6 text-left shadow-xl transform transition-all my-8 sm:align-middle max-w-sm w-full">
                                <div className="sm:flex sm:items-start px-6">
                                    {/*<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">*/}
                                    {/*  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />*/}
                                    {/*</div>*/}
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Heading
                                            headingVariant={
                                                HeadingVariant.BLACK
                                            }
                                            level={Level.HEADING_4}
                                        >
                                            Add a country
                                        </Heading>
                                        <div className="my-4">
                                            <p className="text-sm text-gray-500">
                                                Choose a country to customize.
                                            </p>
                                        </div>
                                        <Select
                                            id="countries"
                                            options={countries}
                                            selected={selectedCountry}
                                            setSelected={setSelectedCountry}
                                        />
                                    </div>
                                </div>
                                <div className="mt-8 flex rounded-b border-t border-brand-gray-200 text-sm h-12">
                                    {/*<Button action={Action.RED} inverted={true} onClick={() => handleAction()}>*/}
                                    {/*  Disconnect*/}
                                    {/*</Button>*/}
                                    {/*<Button action={Action.GRAY} inverted={true} onClick={() => handleClose(false)}>*/}
                                    {/*  Cancel*/}
                                    {/*</Button>*/}
                                    <button
                                        type="button"
                                        className="w-1/2 border-r text-center font-bold text-black focus:outline-none hover:bg-brand-gray-150"
                                        onClick={() => handleClose(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="w-1/2 text-center text-base font-bold text-brand-primary focus:outline-none sm:text-sm hover:bg-brand-gray-150"
                                        onClick={() =>
                                            handleAction(selectedCountry)
                                        }
                                    >
                                        Add country
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default AddCountry
