import React, {PropsWithChildren} from 'react'
import { classNames } from '../../utils/classNames'

type GlobalAccordionProps = {
    isOpen: boolean
    // setIsOpen: Dispatch<SetStateAction<object | null>>
}

interface AccordionHeaderProps extends PropsWithChildren {}

interface AccordionBodyProps extends PropsWithChildren{}

interface AccordionProps extends GlobalAccordionProps, PropsWithChildren {
    classList?: string
}

const AccordionHeader: React.FC<AccordionProps & AccordionHeaderProps> = ({
    isOpen,
    classList,
    children,
}) => {
    if (!classList)
        classList = 'border border-brand-gray-200 px-3 py-2 bg-white'
    let roundedStyle = 'rounded'
    if (isOpen) {
        roundedStyle = 'rounded rounded-b-none'
    }
    return (
        <div
            className={classNames(
                'overflow-hidden transition-border-radius duration-300',
                roundedStyle,
                classList,
            )}
        >
            {children}
        </div>
    )
}
const AccordionBody: React.FC<AccordionProps & AccordionBodyProps> = ({
    isOpen,
    classList,
    children,
}) => {
    if (!classList)
        classList = 'border border-brand-gray-200 px-3 bg-white border-t-0'
    return (
        <div
            className={classNames(
                'overflow-hidden rounded rounded-t-none transition-all duration-300',
                isOpen
                    ? 'max-h-screen py-2 opacity-100'
                    : 'py-0 max-h-0 opacity-0',
                classList,
            )}
        >
            {children}
        </div>
    )
}
const Accordion: React.FC<AccordionProps> = ({
    isOpen,
    classList,
    children,
}) => {
    if (!classList)
        classList = 'border border-brand-gray-200 px-3 py-2 bg-white'
    return <div className={classNames('rounded', classList)}>{children}</div>
}

export default Object.assign(Accordion, {
    Header: AccordionHeader,
    Body: AccordionBody,
})
