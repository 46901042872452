import React from 'react'
import './assets/css/index.css'
import App from './App'
import './plugins/i18n'
import {createRoot} from "react-dom/client";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);