import React, { FormEvent, useContext, useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Heading, { Level } from '../../components/Typography/Heading'
import Button, { Action } from '../../components/Button/Button'
import Select, { FormOption } from '../../components/Form/Select'
import { UserContext } from '../../providers/UserProvider'
import Span from '../../components/Typography/Span'
import Radio from '../../components/Form/Radio'
import { LayoutContext } from '../../providers/LayoutProvider'
import MolliePaymentMethods from './MolliePaymentMethods'
import { Trans, useTranslation } from 'react-i18next'
import { MerchantProfileSave } from '../../plugins/middleware-api-client'
import PlaceholderSelect from '../../components/Form/PlaceholderSelect'
import TestMode from '../../components/TestMode'
import {useNavigate} from "react-router-dom";

const GeneralSettings: React.FC = () => {
    const apiOptions: FormOption[] = [
        {
            label: 'Payments',
            value: 'payments',
        },
        {
            label: 'Orders',
            value: 'orders',
        },
    ]
    const { t } = useTranslation()
    const userContext = useContext(UserContext)
    const layoutContext = useContext(LayoutContext)
    const navigate = useNavigate()
    const [formOptions, setFormOptions] = useState<FormOption[]>([])
    const [testEnabled, setTestEnabled] = useState<boolean>(
        userContext.merchant?.test_mode ?? true,
    )
    const [selectedApiOption, setSelectedApiOption] = useState<FormOption>(
        apiOptions[0],
    )
    const [selectedOption, setSelectedOption] = useState<FormOption | null>(
        null,
    )
    const [automaticRefundsToggle, setAutomaticRefundsToggle] =
        useState<boolean>(true)
    const [useMollieMethodNamesToggle, setUseMollieMethodNamesToggle] =
        useState<boolean>(false)
    const [notifyOnCancellationToggle, setNotifyOnCancellationToggle] =
      useState<boolean>(false)
    const [selectedLocale, setSelectedLocale] = useState<FormOption>({
        label: userContext.merchant?.locale || 'en',
        value: userContext.merchant?.locale || 'en',
    })
    const [isSavingProfile, setIsSavingProfile] = useState<boolean>(false)
    const capitalize = (s: string) =>
        (s && s[0].toUpperCase() + s.slice(1)) || ''
    const [locales, setLocales] = useState<FormOption[]>([selectedLocale])

    useEffect(() => {
        userContext.getProfiles()
        setLocales([
            {
                label: 'en',
                value: 'en',
            },
            {
                label: 'nl',
                value: 'nl',
            },
            {
                label: 'fr',
                value: 'fr',
            },
            {
                label: 'de',
                value: 'de',
            },
        ])
    }, [])

    useEffect(() => {
        if (userContext.profiles && userContext.profiles[0]) {
            const options: FormOption[] = userContext.profiles.map(
                (profile) => {
                    let option = {
                        label: profile.name,
                        value: profile.id,
                    }
                    if (
                        userContext.merchant?.active_mollie_profile_id ===
                        profile.id
                    ) {
                        setSelectedOption(option)
                    }
                    return option
                },
            )
            setFormOptions(options)
        }
        if (userContext.merchant?.mollie_api_type) {
            setSelectedApiOption({
                label: capitalize(userContext.merchant.mollie_api_type),
                value: userContext.merchant.mollie_api_type,
            })
        }
        if (userContext.merchant !== null) {
            setAutomaticRefundsToggle(
                userContext.merchant.process_credit_note_webhook,
            )
            setUseMollieMethodNamesToggle(
                userContext.merchant.use_mollie_method_names,
            )
            setNotifyOnCancellationToggle(
              userContext.merchant.notify_on_cancellation
            )
        }
    }, [
        userContext.merchant?.active_mollie_profile_id,
        userContext.merchant?.mollie_api_type,
        userContext.profiles,
    ])

    const selectProfile = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSavingProfile(true)
        if (selectedOption?.value && selectedApiOption.value) {
            let merchantProfileSave: MerchantProfileSave = {
                mollie_api_type: selectedApiOption.value,
                profile_id: selectedOption.value,
                process_credit_note_webhook: automaticRefundsToggle,
                use_mollie_method_names: useMollieMethodNamesToggle,
                notify_on_cancellation: notifyOnCancellationToggle
            }
            try {
                await userContext.postProfile(merchantProfileSave)
                setIsSavingProfile(false)
                userContext.getPaymentMethods(t('payment:default-settings'))
            } catch (e) {
                setIsSavingProfile(false)
                console.log(e)
            }
        }
    }

    useEffect(() => {
        if (
            userContext.merchant &&
            (testEnabled !== userContext.merchant.test_mode ||
                selectedLocale.label !== userContext.merchant.locale)
        ) {
            merchantSettingsPost()
        }
    }, [testEnabled, selectedLocale])

    const merchantSettingsPost = () => {
        userContext.merchantSettingsPost(
            testEnabled,
            selectedLocale.value ?? 'en',
        )
    }

    return (
        <>
            <div className="flex flex-col min-h-screen">
                <TestMode
                    testMode={testEnabled}
                    setTestMode={(testmode: boolean) =>
                        setTestEnabled(testmode)
                    }
                />
                <Header>{t('account:title')}</Header>
                <div className="flex flex-col min-h-screen py-10 px-8">
                    <div className="flex justify-between">
                        <Heading
                            headingVariant={4}
                            level={Level.HEADING_4}
                            tag="h3"
                        >
                            {t('account:profile.website')}
                        </Heading>
                        <div className="flex">
                            <Select
                                id="country"
                                className="px-3"
                                options={locales}
                                selected={selectedLocale}
                                setSelected={setSelectedLocale}
                            />
                            <Radio
                                className="ml-3"
                                enabled={testEnabled}
                                setEnabled={setTestEnabled}
                                label={t('account:test-mode') || ''}
                            />
                        </div>
                    </div>
                    <div>
                        <form onSubmit={selectProfile} className="text-left">
                            <div className="my-6">
                                <Span classList="text-sm leading-4">
                                    {t('account:profile.text')}
                                </Span>
                            </div>
                            <div className="max-w-xs min-h-[62px]">
                                {selectedOption ? (
                                    <Select
                                        id="profile"
                                        label={t('account:profile.label') || ''}
                                        options={formOptions}
                                        selected={selectedOption}
                                        setSelected={setSelectedOption}
                                    />
                                ) : (
                                    <PlaceholderSelect
                                        label={t('account:profile.label') || ''}
                                    />
                                )}
                            </div>
                            <div className="my-6">
                                <Span classList="text-sm leading-4">
                                    {t('account:profile.mollie-api-type-text')}
                                </Span>
                                <Span classList="text-sm leading-4 block mt-1">
                                    <Trans
                                        i18nKey="account:profile.mollie-api-type-documentation"
                                        components={[
                                            <a
                                                target="_blank"
                                                className="underline"
                                                href="https://docs.mollie.com/orders/why-use-orders"
                                            >
                                                Click here
                                            </a>,
                                        ]}
                                    />
                                </Span>
                            </div>
                            <div className="max-w-xs">
                                <Select
                                    id="mollie-api-type"
                                    label={
                                        t('account:profile.mollie-api-type') ||
                                        ''
                                    }
                                    options={apiOptions}
                                    selected={selectedApiOption}
                                    setSelected={setSelectedApiOption}
                                />
                            </div>
                            <div className="my-6">
                                <Radio
                                    enabled={automaticRefundsToggle}
                                    setEnabled={setAutomaticRefundsToggle}
                                    label={t('account:automatic-refund') || ''}
                                />
                                <Span classList="text-sm leading-4">
                                    {t('account:automatic-refund-description')}
                                </Span>
                            </div>
                            <div className="my-6">
                                <Radio
                                    enabled={useMollieMethodNamesToggle}
                                    setEnabled={setUseMollieMethodNamesToggle}
                                    label={t('account:use-mollie-method-names') || ''}
                                />
                                <Span classList="text-sm leading-4">
                                    {t('account:use-mollie-method-names-description')}
                                </Span>
                            </div>
                            <div className="my-6">
                                <Radio
                                  enabled={notifyOnCancellationToggle}
                                  setEnabled={setNotifyOnCancellationToggle}
                                  label={t('account:notify-on-cancellation') || ''}
                                />
                                <Span classList="text-sm leading-4">
                                    {t('account:notify-on-cancellation-description')}
                                </Span>
                            </div>
                            <div className="flex justify-between pt-8">
                                <Button loading={isSavingProfile}>
                                    {t('account:profile.save')}
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div className="max-w-md mt-16">
                        <Heading
                            headingVariant={4}
                            level={Level.HEADING_4}
                            tag="h3"
                            classList="pb-6"
                        >
                            {t('account:profile.disconnect-title')}
                        </Heading>
                        <div className="pb-4">
                            <Button
                              action={Action.GRAY}
                              onClick={() => navigate('/connect')}
                              classList="mr-4"
                            >
                                {t('connect:switch-organization')}
                            </Button>
                            <Button
                                action={Action.RED}
                                onClick={() =>
                                    layoutContext.setShowAlertModal(true)
                                }
                            >
                                {t('account:profile.disconnect')}
                            </Button>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <MolliePaymentMethods />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GeneralSettings
