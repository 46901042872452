import React, {useContext, useEffect, useState} from 'react'
import Header from '../../components/Header/Header'
import Heading, {Level} from '../../components/Typography/Heading'
import Button, {Action} from '../../components/Button/Button'
import Select, {FormOption} from '../../components/Form/Select'
import {UserContext} from '../../providers/UserProvider'
import PaymentMethodComponent from './components/PaymentMethod'
import AddCountry from '../../components/Modal/AddCountry'
import {useTranslation} from 'react-i18next'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {SortableItem} from '../../components/SortableItem/SortableItem'
import {
  MethodsOrderSave,
  MollieMethod,
} from '../../plugins/middleware-api-client'
import PlaceholderPaymentMethod from '../../components/PlaceholderPaymentMethod'
import PaymentMethodProvider from '../../providers/PaymentMethodProvider'
import RemoveCountry from '../../components/Modal/RemoveCountry'
import TestMode from '../../components/TestMode'

const PaymentMethods: React.FC = () => {
  const {t} = useTranslation()
  const defaultCountry = [
    {
      value: null,
      label: t('payment:default-settings'),
    },
  ]
  const userContext = useContext(UserContext)
  const [testEnabled, setTestEnabled] = useState<boolean>(
    userContext.merchant?.test_mode ?? true,
  )
  const [countries, setCountries] = useState<FormOption[]>(defaultCountry)
  const [applicationCountries, setApplicationCountries] = useState<
    FormOption[]
  >([])
  const [selectedCountry, setSelectedCountry] = useState<FormOption>({
    value: null,
    label: t('payment:default-settings'),
  })
  const [isArrangingMethods, setIsArrangingMethods] = useState<boolean>(false)
  const [showCountryModal, setShowCountryModal] = useState<boolean>(false)
  const [showRemoveCountryModal, setShowRemoveCountryModal] =
    useState<boolean>(false)
  const [paymentMethods, setPaymentMethods] = useState<MollieMethod[]>([])

  useEffect(() => {
    userContext.setPaymentMethods([])
    userContext
      .getPaymentMethods(selectedCountry.value)
      .then((res) => setPaymentMethods(res))
    userContext.getCountries()
  }, [])

  useEffect(() => {
    const mappedCountries: FormOption[] = []
    const mappedApplicationCountries: FormOption[] = []
    userContext.countries.map((country) => {
      if (country.merchantSetting) {
        mappedCountries.push({
          value: country.code,
          label: country.name ?? country.code,
        })
      } else {
        mappedApplicationCountries.push({
          value: country.code,
          label: country.name ?? country.code,
        })
      }
    })
    setCountries([...defaultCountry, ...mappedCountries])
    setApplicationCountries(mappedApplicationCountries)
  }, [userContext.countries])

  useEffect(() => {
    setPaymentMethods([])
    userContext.getPaymentMethods(selectedCountry.value).then()
  }, [selectedCountry])

  useEffect(() => {
    setPaymentMethods(userContext.paymentMethods)
  }, [userContext.paymentMethods])

  const addCountry = async (newCountry: FormOption) => {
    if (newCountry.value) {
      const response = await userContext.addCountrySetting({
        code: newCountry.value,
        name: newCountry.label,
      })
      if (response) {
        await userContext.getCountries()
        setShowCountryModal(false)
        setSelectedCountry(newCountry)
      }
    }
  }

  const removeCountry = async (country: FormOption) => {
    if (country.value) {
      const response = await userContext.removeCountrySetting({
        code: country.value,
        name: country.label,
      })
      if (response) {
        await userContext.getCountries()
        setShowRemoveCountryModal(false)
        setSelectedCountry(countries[0])
      }
    }
  }

  const openRemoveCountryModal = () => {
    if (selectedCountry.value) setShowRemoveCountryModal(true)
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: any) => {
    const {active, over} = event

    if (active.id === over.id) {
      return
    }

    let oldIndex = userContext.paymentMethods.findIndex(
      (paymentMethod) => {
        return paymentMethod.id === active.id
      },
    )
    let newIndex = userContext.paymentMethods.findIndex(
      (paymentMethod) => {
        return paymentMethod.id === over.id
      },
    )
    // TODO: Loop new order and set sorting
    let newOrder = arrayMove(
      userContext.paymentMethods,
      oldIndex,
      newIndex,
    )
    let newMethodsOrder: MethodsOrderSave = {
      country: selectedCountry.value,
      methods: [],
    }
    newOrder.map((paymentMethod: MollieMethod, index) => {
      newMethodsOrder.methods[index] = {
        mollie_name: paymentMethod.id,
        position: index + 1,
        enabled: paymentMethod.enabled,
      }
    })
    setPaymentMethods(newOrder)
    userContext.updatePaymentMethodsOrder(
      newMethodsOrder,
      selectedCountry.value,
    )
  }

  useEffect(() => {
    if (
      userContext.merchant &&
      testEnabled !== userContext.merchant.test_mode
    ) {
      userContext.merchantSettingsPost(
        testEnabled,
        userContext.merchant.locale ?? 'en',
      )
    }
  }, [testEnabled])

  return (
    <div className="flex flex-col min-h-screen">
      <TestMode
        testMode={testEnabled}
        setTestMode={(testmode: boolean) =>
          setTestEnabled(testmode)
        }
      />
      <Header>{t('payment:payment-methods')}</Header>
      <div className="grid grid-cols-12 py-20">
        <div className="col-start-3 col-span-8">
          <div className="flex justify-items-center">
            <div className="w-60">
              <Select
                id="country"
                options={countries}
                selected={selectedCountry}
                setSelected={setSelectedCountry}
              />
            </div>
            <button
              onClick={openRemoveCountryModal}
              className="flex items-center pl-6 text-sm font-bold text-brand-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 ${
                  !selectedCountry.value
                    ? 'text-brand-secondary'
                    : 'text-brand-red'
                } mr-2`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11,9 L9,9 L5,9 L5,11 L9,11 L11,11 L15,11 L15,9 L11,9 Z M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z"
                />
              </svg>
              {t('payment:remove-country')}
            </button>
            <button
              onClick={() => setShowCountryModal(true)}
              className="flex items-center pl-6 text-sm font-bold text-brand-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-brand-primary mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11,9 L11,5 L9,5 L9,9 L5,9 L5,11 L9,11 L9,15 L11,15 L11,11 L15,11 L15,9 L11,9 Z M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z"
                />
              </svg>
              {t('payment:add-country')}
            </button>
            <RemoveCountry
              show={showRemoveCountryModal}
              handleClose={setShowRemoveCountryModal}
              handleAction={removeCountry}
              country={selectedCountry}
            />
            <AddCountry
              show={showCountryModal}
              handleClose={setShowCountryModal}
              handleAction={addCountry}
              countries={applicationCountries}
            />
          </div>
          <div className="pt-12">
            <div className="flex items-center justify-between">
              <Heading
                headingVariant={4}
                level={Level.HEADING_4}
                tag="h3"
              >
                {selectedCountry.label}
              </Heading>
              <Button
                action={Action.BLUE}
                inverted={true}
                onClick={() =>
                  setIsArrangingMethods(
                    !isArrangingMethods,
                  )
                }
              >
                {isArrangingMethods
                  ? t('payment:settings.arrangement')
                  : t('payment:settings.arrange')}
              </Button>
            </div>
            <div className="grid grid-cols-12">
              {paymentMethods.length > 0 ? (
                <div className="col-span-12">
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={paymentMethods}
                      strategy={
                        verticalListSortingStrategy
                      }
                    >
                      <PaymentMethodProvider>
                        {/* //TODO: Remove temporary `.filter(method => method.id !== "voucher")` when vouchers are resolved! */}
                        {paymentMethods
                          .filter(
                            (method) =>
                              method.id !==
                              'voucher',
                          )
                          .map(
                            (
                              paymentMethod,
                              index,
                            ) => {
                              return (
                                <SortableItem
                                  key={index}
                                  id={paymentMethod.id}
                                  isArrangingMethods={isArrangingMethods}
                                >
                                  <PaymentMethodComponent
                                    key={index}
                                    paymentMethod={paymentMethod}
                                    selectedCountry={selectedCountry}
                                    isArrangingMethods={isArrangingMethods}
                                  />
                                </SortableItem>
                              )
                            },
                          )}
                      </PaymentMethodProvider>
                    </SortableContext>
                  </DndContext>
                </div>
              ) : (
                <div className="col-span-12">
                  {' '}
                  {[...Array(10)].map((_, index) => (
                    <PlaceholderPaymentMethod
                      key={`placeholder-index-${index}`}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethods
