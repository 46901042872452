/* tslint:disable */
/* eslint-disable */
/**
 * Mollie - Lightspeed Payments App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ApplicationCountries } from '../models';
// @ts-ignore
import type { ApplicationCountry } from '../models';
// @ts-ignore
import type { ApplicationLocales } from '../models';
// @ts-ignore
import type { LightspeedShopSettings } from '../models';
// @ts-ignore
import type { Merchant } from '../models';
// @ts-ignore
import type { MerchantPaymentMethodDisable } from '../models';
// @ts-ignore
import type { MerchantPaymentMethodEnable } from '../models';
// @ts-ignore
import type { MerchantProfileSave } from '../models';
// @ts-ignore
import type { MethodsOrderSave } from '../models';
// @ts-ignore
import type { MollieMethods } from '../models';
// @ts-ignore
import type { MollieProfiles } from '../models';
// @ts-ignore
import type { PaymentMethodUpdate } from '../models';
// @ts-ignore
import type { TransactionPollStatus } from '../models';
/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the settings for a certain country
         * @param {ApplicationCountry} [applicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantCountriesDelete: async (applicationCountry?: ApplicationCountry, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationCountry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the available countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantCountriesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the available countries
         * @param {ApplicationCountry} [applicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantCountriesPost: async (applicationCountry?: ApplicationCountry, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationCountry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the active Mollie organization and profile to null in database, meaning it cant be used for new payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantDisconnectPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/disconnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the available locales that can be used as setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantLocalesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint will return a merchant object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disabled payment method in mollie
         * @param {MerchantPaymentMethodDisable} [merchantPaymentMethodDisable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsDisablePost: async (merchantPaymentMethodDisable?: MerchantPaymentMethodDisable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/methods/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(merchantPaymentMethodDisable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enabled payment method in mollie
         * @param {MerchantPaymentMethodEnable} [merchantPaymentMethodEnable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsEnablePost: async (merchantPaymentMethodEnable?: MerchantPaymentMethodEnable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/methods/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(merchantPaymentMethodEnable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all available methods for the active organization and profile
         * @param {string} [country] 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsGet: async (country?: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves the order of the payment methods provided
         * @param {MethodsOrderSave} [methodsOrderSave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsOrderPost: async (methodsOrderSave?: MethodsOrderSave, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/methods/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(methodsOrderSave, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the individual payment settings
         * @param {PaymentMethodUpdate} [paymentMethodUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsPut: async (paymentMethodUpdate?: PaymentMethodUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentMethodUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save Mollie profile to shop
         * @param {MerchantProfileSave} [merchantProfileSave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantProfilePost: async (merchantProfileSave?: MerchantProfileSave, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(merchantProfileSave, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint will return all availble mollie profiles for the merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantProfilesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves settings on Lightspeed shop level
         * @param {LightspeedShopSettings} [lightspeedShopSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantSettingsPost: async (lightspeedShopSettings?: LightspeedShopSettings, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lightspeedShopSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This URL will return the transaction status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollTransaction: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/poll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the settings for a certain country
         * @param {ApplicationCountry} [applicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantCountriesDelete(applicationCountry?: ApplicationCountry, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantCountriesDelete(applicationCountry, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantCountriesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists the available countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantCountriesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCountries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantCountriesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantCountriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists the available countries
         * @param {ApplicationCountry} [applicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantCountriesPost(applicationCountry?: ApplicationCountry, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantCountriesPost(applicationCountry, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantCountriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set the active Mollie organization and profile to null in database, meaning it cant be used for new payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantDisconnectPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantDisconnectPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantDisconnectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists the available locales that can be used as setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantLocalesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationLocales>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantLocalesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantLocalesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint will return a merchant object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Merchant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Disabled payment method in mollie
         * @param {MerchantPaymentMethodDisable} [merchantPaymentMethodDisable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantMethodsDisablePost(merchantPaymentMethodDisable?: MerchantPaymentMethodDisable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantMethodsDisablePost(merchantPaymentMethodDisable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantMethodsDisablePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Enabled payment method in mollie
         * @param {MerchantPaymentMethodEnable} [merchantPaymentMethodEnable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantMethodsEnablePost(merchantPaymentMethodEnable?: MerchantPaymentMethodEnable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantMethodsEnablePost(merchantPaymentMethodEnable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantMethodsEnablePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all available methods for the active organization and profile
         * @param {string} [country] 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantMethodsGet(country?: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MollieMethods>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantMethodsGet(country, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantMethodsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Saves the order of the payment methods provided
         * @param {MethodsOrderSave} [methodsOrderSave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantMethodsOrderPost(methodsOrderSave?: MethodsOrderSave, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantMethodsOrderPost(methodsOrderSave, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantMethodsOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the individual payment settings
         * @param {PaymentMethodUpdate} [paymentMethodUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantMethodsPut(paymentMethodUpdate?: PaymentMethodUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantMethodsPut(paymentMethodUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantMethodsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save Mollie profile to shop
         * @param {MerchantProfileSave} [merchantProfileSave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantProfilePost(merchantProfileSave?: MerchantProfileSave, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantProfilePost(merchantProfileSave, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantProfilePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint will return all availble mollie profiles for the merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantProfilesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MollieProfiles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantProfilesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantProfilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Saves settings on Lightspeed shop level
         * @param {LightspeedShopSettings} [lightspeedShopSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantSettingsPost(lightspeedShopSettings?: LightspeedShopSettings, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantSettingsPost(lightspeedShopSettings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.merchantSettingsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This URL will return the transaction status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollTransaction(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionPollStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pollTransaction(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.pollTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * Removes the settings for a certain country
         * @param {ApplicationCountry} [applicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantCountriesDelete(applicationCountry?: ApplicationCountry, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantCountriesDelete(applicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the available countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantCountriesGet(options?: RawAxiosRequestConfig): AxiosPromise<ApplicationCountries> {
            return localVarFp.merchantCountriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the available countries
         * @param {ApplicationCountry} [applicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantCountriesPost(applicationCountry?: ApplicationCountry, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantCountriesPost(applicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the active Mollie organization and profile to null in database, meaning it cant be used for new payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantDisconnectPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantDisconnectPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Lists the available locales that can be used as setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantLocalesGet(options?: RawAxiosRequestConfig): AxiosPromise<ApplicationLocales> {
            return localVarFp.merchantLocalesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint will return a merchant object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMeGet(options?: RawAxiosRequestConfig): AxiosPromise<Merchant> {
            return localVarFp.merchantMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Disabled payment method in mollie
         * @param {MerchantPaymentMethodDisable} [merchantPaymentMethodDisable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsDisablePost(merchantPaymentMethodDisable?: MerchantPaymentMethodDisable, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantMethodsDisablePost(merchantPaymentMethodDisable, options).then((request) => request(axios, basePath));
        },
        /**
         * Enabled payment method in mollie
         * @param {MerchantPaymentMethodEnable} [merchantPaymentMethodEnable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsEnablePost(merchantPaymentMethodEnable?: MerchantPaymentMethodEnable, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantMethodsEnablePost(merchantPaymentMethodEnable, options).then((request) => request(axios, basePath));
        },
        /**
         * List all available methods for the active organization and profile
         * @param {string} [country] 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsGet(country?: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<MollieMethods> {
            return localVarFp.merchantMethodsGet(country, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves the order of the payment methods provided
         * @param {MethodsOrderSave} [methodsOrderSave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsOrderPost(methodsOrderSave?: MethodsOrderSave, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantMethodsOrderPost(methodsOrderSave, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the individual payment settings
         * @param {PaymentMethodUpdate} [paymentMethodUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantMethodsPut(paymentMethodUpdate?: PaymentMethodUpdate, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantMethodsPut(paymentMethodUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Save Mollie profile to shop
         * @param {MerchantProfileSave} [merchantProfileSave] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantProfilePost(merchantProfileSave?: MerchantProfileSave, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantProfilePost(merchantProfileSave, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint will return all availble mollie profiles for the merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantProfilesGet(options?: RawAxiosRequestConfig): AxiosPromise<MollieProfiles> {
            return localVarFp.merchantProfilesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Saves settings on Lightspeed shop level
         * @param {LightspeedShopSettings} [lightspeedShopSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantSettingsPost(lightspeedShopSettings?: LightspeedShopSettings, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.merchantSettingsPost(lightspeedShopSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * This URL will return the transaction status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollTransaction(options?: RawAxiosRequestConfig): AxiosPromise<TransactionPollStatus> {
            return localVarFp.pollTransaction(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * Removes the settings for a certain country
     * @param {ApplicationCountry} [applicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantCountriesDelete(applicationCountry?: ApplicationCountry, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantCountriesDelete(applicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the available countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantCountriesGet(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantCountriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the available countries
     * @param {ApplicationCountry} [applicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantCountriesPost(applicationCountry?: ApplicationCountry, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantCountriesPost(applicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set the active Mollie organization and profile to null in database, meaning it cant be used for new payments.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantDisconnectPost(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantDisconnectPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists the available locales that can be used as setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantLocalesGet(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantLocalesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint will return a merchant object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantMeGet(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disabled payment method in mollie
     * @param {MerchantPaymentMethodDisable} [merchantPaymentMethodDisable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantMethodsDisablePost(merchantPaymentMethodDisable?: MerchantPaymentMethodDisable, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantMethodsDisablePost(merchantPaymentMethodDisable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enabled payment method in mollie
     * @param {MerchantPaymentMethodEnable} [merchantPaymentMethodEnable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantMethodsEnablePost(merchantPaymentMethodEnable?: MerchantPaymentMethodEnable, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantMethodsEnablePost(merchantPaymentMethodEnable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all available methods for the active organization and profile
     * @param {string} [country] 
     * @param {string} [locale] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantMethodsGet(country?: string, locale?: string, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantMethodsGet(country, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves the order of the payment methods provided
     * @param {MethodsOrderSave} [methodsOrderSave] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantMethodsOrderPost(methodsOrderSave?: MethodsOrderSave, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantMethodsOrderPost(methodsOrderSave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the individual payment settings
     * @param {PaymentMethodUpdate} [paymentMethodUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantMethodsPut(paymentMethodUpdate?: PaymentMethodUpdate, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantMethodsPut(paymentMethodUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save Mollie profile to shop
     * @param {MerchantProfileSave} [merchantProfileSave] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantProfilePost(merchantProfileSave?: MerchantProfileSave, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantProfilePost(merchantProfileSave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint will return all availble mollie profiles for the merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantProfilesGet(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantProfilesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves settings on Lightspeed shop level
     * @param {LightspeedShopSettings} [lightspeedShopSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public merchantSettingsPost(lightspeedShopSettings?: LightspeedShopSettings, options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).merchantSettingsPost(lightspeedShopSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This URL will return the transaction status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public pollTransaction(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).pollTransaction(options).then((request) => request(this.axios, this.basePath));
    }
}

